import gql from 'graphql-tag'
import {
  GeometricLocation,
  RatingReview,
  RentType,
  LookUp,
  BoatRent,
  Role,
  Like,
  PageVisit,
  DocumentVerification,
  AddressType,
  ImageType,
  TripDetailsType,
} from './schemaTypes/schemaTypes'

export const createRentBoat = gql`
  mutation createRentBoat($input: BoatRentInput!) {
    createRentBoat(input: $input) {
      id
    }
  }
`

export const getBoatRentLookUps = gql`
  {
    getBoatRentLookUps {
      typeName
      lookUp {
        id
        alias
        typeId {
          id
          type
        }
      }
    }
  }
`

export const getAllRentTypes = gql`
  query getAllRentTypes($page: Int, $limit: Int, $isAdmin: Boolean = false){
    getAllRentTypes(page: $page, limit: $limit, isAdmin: $isAdmin) {
      items {
        id
        name
        thumbnailIcon ${ImageType}
        tripId{
          id
          alias
        }
        createdAt
        updatedAt
      }
      total
    }
  }`

export const getUserBoatRent = gql`
  query rentBoatByUser($page: Int!, $limit: Int!, $sort: SortType, $sortKey: String) {
    rentBoatByUser(page: $page, limit: $limit, sort: $sort, sortKey: $sortKey) {
      items ${BoatRent}
      total
    }
  }
`

export const deleteBoatRent = gql`
  mutation deleteRentBoat($id: String!) {
    deleteRentBoat(id: $id) {
      id
    }
  }
`
export const rentCategoryWiseBoats = gql`
  query getRentBoatsByTripType(
    $page: Int
    $limit: Int
    $tripTypeId: String!
    $country: String,
    $latLng: CoordinateInput
  ) {
    getRentBoatsByTripType(
      page: $page
      limit: $limit
      tripTypeId: $tripTypeId
      country: $country
      latLng: $latLng
    ) {
      items {
        id
        adId
        address ${AddressType}
        total
        captainName
        startDate
        startTime
        available
        trip ${RentType}
        tripType ${RentType}
        endDate
        endTime
        tripDetails ${TripDetailsType}
        engine
        boatLength
        tripDuration
        bedroomAndCabins
        yearOfManufacturer
        maximumGuest
        manufacturer
        model
        bathrooms
        seatsOnBoat
        deckAndEntertainment {
          id
          alias
        }
        whatToBring
        price
        descriptionOfOurTrip
        policy
        deposit {
          id
          alias
        }
        images ${ImageType}
        youtubeLinkVideo
        boatLayout ${ImageType}
        rating
        user {
          id
          firstName
          lastName
          role {
            id
            role
          }
        }
      }
      total
    }
  }
`

export const getRentBoatInnerQuery = gql`
  query editRentBoat($id: String!, $forBooking: Boolean) {
    editRentBoat(id: $id, forBooking: $forBooking) {
      id
      adStatus
      rating
      verifiedFirstTime
      pageVisits ${PageVisit}
      likes ${Like}
      images ${ImageType}
      boatLayout ${ImageType}
      address ${AddressType}
      captainName
      checkLocationFlag
      deckAndEntertainment{
        id
        alias
        icon {
          id
          url
        }
      }
      boatLength
      bedroomAndCabins
      maximumGuest
      bathrooms
      seatsOnBoat
      whatToBring
      price
      descriptionOfOurTrip
      promotionOnSite
      deposit{
        id
        alias
      }
      costOfDeposit
      youtubeLinkVideo
      youtubeLinkVideo_1
      youtubeLinkVideo_2
      boatRentStatus
      total
      model
      manufacturer
      yearOfManufacturer
      startTime
      tripDuration
      endTime
      tripDetails ${TripDetailsType}
      engine
      timezoneName
      policy
      user {
        createdAt
        address ${AddressType}
        like
        title
        firstName
        lastName
        companyWebsite
        mobileNumber
        language
        companyName
        companyLogo ${ImageType}
        token
        id
        email
        language
        isVerified
        accountActivated
        documentVerification ${DocumentVerification}
        provider ${LookUp}
        role ${Role}
        image ${ImageType}
        governmentId ${ImageType}
        commercialLicence ${ImageType}
        reviews ${RatingReview}
        paymentMethodTypes {
          id
          name
          icon {
            id
            url
          }
        }
      }
      tripType ${RentType}
      trip ${RentType}
      adId
      reviews ${RatingReview}
      createdAt
      startDate
      endDate
      meta_tags
    }
  }
`

export const updateRentBoat = gql`
  mutation updateRentBoat($input: BoatRentInput!) {
    updateRentBoat(input: $input) {
      id
    }
  }
`

export const getSimilarBoatRents = gql`
  query getSimilarBoatRents($id: String!){
    getSimilarBoatRents(id: $id){
      items ${BoatRent}
      total
    }
  }
`

export const getRentBoatTripDetails_query = gql`
  query getRentBoatTripDetails($boatId: String!, $reqId: String!) {
    getRentBoatTripDetails(boatId: $boatId, reqId: $reqId) {
      slots {
        _id
        type
        startDate
        startTime
        endDate
        endTime
        price
        total
        available
      }
      bookings {
        _id
        ticketId
        slot
        units
        status
        bookedAt
        isQRScanned
        isCancelled
        customer {
          _id
          firstName
          lastName
          companyName
        }
        payment {
          _id
          totalAmount
          paymentIntentId
        }
      }
      timezoneName
    }
  }
`

export const updateRentBoatTripDetails_query = gql`
  mutation updateRentBoatTripDetails($id: String!, $input: MainTripTypeInput!) {
    updateRentBoatTripDetails(id: $id, input: $input)
  }
`

export const boatRentChangeStatus = gql`
  mutation boatRentChangeStatus($value: Boolean!, $columnName: String!, $id: String!) {
    boatRentChangeStatus(value: $value, columnName: $columnName, id: $id) {
      message
      statusCode
    }
  }
`

export const getRentTicketSchema = gql`
  query getRentTicket($bookingId: String!) {
    getRentTicket(bookingId: $bookingId) {
      _id
      ticketId
      boat {
        _id
        adId
        user {
          _id
          firstName
          lastName
          mobileNumber
          image {
            id
            url
          }
          companyName
        }
        model
        captainName
        tripType {
          _id
          alias
        }
        address ${AddressType}
        tripDetails {
          _id
          type
          startDate
          startTime
          endDate
          endTime
          price
          total
          available
        }
        whatToBring
        deposit {
          id
          alias
        }
        costOfDeposit
        descriptionOfOurTrip
        policy
      }
      customer {
        _id
        firstName
        lastName
        companyName
      }
      slot
      payment {
        _id
        baseAmount
        serviceFee
        stripeFee
        totalAmount
      }
      units
      isCancelled
    }
  }
`

export const cancelRentBookingQuery = gql`
  mutation cancelRentBooking($bookingId: String!) {
    cancelRentBooking(bookingId: $bookingId)
  }
`

export const getRentPayoutsQuery = gql`
  query getRentPayouts($limit: Float, $page: Float, $search: String) {
    getRentPayouts(limit: $limit, page: $page, search: $search) {
      _id
      ticketId
      boat {
        _id
        adId
        model
        tripType {
          _id
          alias
        }
        user {
          _id
          firstName
          lastName
          stripe_account_id
        }
      }
      payment {
        _id
        releaseId
        releasedAmount
        releasedAt
      }
    }
  }
`
